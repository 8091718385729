import { t } from '@lingui/macro'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { identity, map, maxBy, minBy, pipe } from 'remeda'
import { useGuardianContext } from '../routes/institution/guardian/guardian-context'
import TaxCertificateDownloadLink from './tax-certificate-download-link'
import { useInstitutionContext } from '../routes/institution/institution-context'

const { Title } = Typography
type Props = {}

const TaxCertificates = (props: Props) => {
  const family = useGuardianContext((state) => state.family)
  const { institution } = useInstitutionContext()
  const acceptedContracts =
    family?.children.flatMap((child) => child.contracts).filter((contract) => contract.state === 'accepted') || []
  const firstYear =
    pipe(
      acceptedContracts,
      map((contract) => dayjs(contract.startDate).year()),
      minBy(identity)
    ) || dayjs().year()
  const lastYear =
    pipe(
      acceptedContracts,
      map((contract) => dayjs(contract.endDate).year()),
      maxBy(identity)
    ) || dayjs().year()

  const lastCompletedYear = Math.min(lastYear, dayjs().year() - 1)
  const taxCertificateYears = Array.from({ length: lastCompletedYear - firstYear + 1 }, (_, index) => firstYear + index)

  return (
    <>
      <Title level={3}>{t({ message: 'Steuerbescheinigungen' })}</Title>
      {taxCertificateYears.map((year) => {
        return (
          <TaxCertificateDownloadLink
            institutionId={institution?.id || ''}
            familyId={family?.id || ''}
            year={year}
            key={year}
          />
        )
      })}
    </>
  )
}

export default TaxCertificates
